/*glas-ai-brain*/

.glas-ai-brain {
    .heading {
        left: -30%;
        width: 130%;
        @media screen and (max-width: 991px) {
            margin-bottom: 20px;
        }
        @media screen and (min-width: 992px) and (max-width: 1023px) {
            left: 0;
        }
    }
    .columns.centered-col {
        @media screen and (max-width: 1366px) {
            float: left;
        }
    }
    .svg-wrapper {
        width: 63%;
        display: block;
        float: right;
        position: absolute;
        top: 120px;
        right: 15px;
        @media screen and (max-width: 767px) {
            top: auto;
            left: 50%;
            width: 80%;
            transform: translateX(-50%);
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            top: auto;
            left: 50%;
            transform: translateX(-50%);
            width: 65%;
        }
    }
    .custom-template {
        margin: 70px 20px 0px 40px;
        @media screen and (max-width: 767px) {
            margin: 100px 0px 20px 0px;
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            margin: 100px 0px 20px 0px;
        }
    }
}