
@font-face {
    font-family: 'DINPro-Bold';
    src: url('../fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/DINPro-Bold.otf')  format('opentype'),
           url('../fonts/DINPro-Bold.woff') format('woff'), url('../fonts/DINPro-Bold.ttf')  format('truetype'), url('../fonts/DINPro-Bold.svg#DINPro-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'DINPro-Light';
    src: url('../fonts/DINPro-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/DINPro-Light.otf')  format('opentype'),
           url('../fonts/DINPro-Light.woff') format('woff'), url('../fonts/DINPro-Light.ttf')  format('truetype'), url('../fonts/DINPro-Light.svg#DINPro-Light') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'DINPro-Medium';
    src: url('../fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/DINPro-Medium.otf')  format('opentype'),
           url('../fonts/DINPro-Medium.woff') format('woff'), url('../fonts/DINPro-Medium.ttf')  format('truetype'), url('../fonts/DINPro-Medium.svg#DINPro-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'EurostileBQ-BoldExtended';
    src: url('../fonts/EurostileBQ-BoldExtended.eot?#iefix') format('embedded-opentype'),  url('../fonts/EurostileBQ-BoldExtended.otf')  format('opentype'),
           url('../fonts/EurostileBQ-BoldExtended.woff') format('woff'), url('../fonts/EurostileBQ-BoldExtended.ttf')  format('truetype'), url('../fonts/EurostileBQ-BoldExtended.svg#EurostileBQ-BoldExtended') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  