$font-din-light: "DINPro-Light",
sans-serif;
$font-din-bold: "DINPro-Bold",
sans-serif;
$font-din-medium: "DINPro-Medium",
sans-serif;
$font-futura: "futura-pt",
sans-serif;
$font-din-condensed: "din-condensed",
sans-serif;

$color-cyan: #188ddd;


::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #666;
    opacity: 0.5;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #666;
    opacity: 0.5;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #666;
    opacity: 1;
    opacity: 0.5;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #666;
    opacity: 0.5;
}


html {
    height: 100%;

    &.noscroll {
        overflow: hidden;
    }
}

body {
    height: calc(100%);
    padding-bottom: 160px;
    background: $body-background;
    color: #000;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;

    font-weight: normal;

    &.noscroll {
        overflow: hidden;
    }
}

h1 {
    text-transform: uppercase;
    font-size: 70px;
    color: #484949;
    font-family: $font-din-medium;
    line-height: 70px;
    opacity: 0;
}

p {
    font-family: $font-din-light;
    font-size: 15px;
}

.box {
    padding: 30px;
    opacity: 0;

    &.gradient {
        background: #fff;
    }

    &.shadow {
        box-shadow: -30px 30px 68px rgba(143, 143, 143, .5);
        margin-bottom: 70px;

        @include breakpoint(small only) {
            margin-bottom: 40px;
            box-shadow: 0 5px 10px rgba(143, 143, 143, .2)
        }
    }
}

.box.gradient.shadow.mb0:before,
.box.gradient.shadow.mb0:after {
    display: table;
    clear: both;
    content: "";
}

#top-background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 320px;
    background-color: #fff;
    z-index: -2;
}

section.page {
    padding: 40px 0 170px 0;

    .page-template-contact &,
    .contact & {
        padding: 40px 0 110px 0;

        @include breakpoint(small only) {
            padding: 0 0 0px 0;
        }
    }

    @include breakpoint(small only) {
        padding: 0 0 20px 0;
    }
}

.wave {
    position: absolute;
    z-index: 0;
    background-size: contain;
    height: 100%;
    width: 100%;
    max-width: 100%;
    top: 37px;
    max-width: 1600px;
    max-height: 564px;
    background-image: url(../img/bg-template.png);
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0;
    margin: auto;
    left: 0;
    right: 0;

    @include breakpoint(small only) {
        display: none;
    }
}

.bg-template {
    position: absolute;
    z-index: 0;
    background-size: contain;
    height: 58%;
    width: 100%;
    max-width: 100%;
    background-image: url(../img/bg-template.png);
    background-repeat: no-repeat;
    background-position: center center;

    @include breakpoint(small only) {
        display: none;
    }
}

.slide {
    display: none;
}

.text-block {
    font-size: 14px;
    overflow: hidden;
    position: relative;
    // z-index: 2;
    display: block;

    @include breakpoint(small only) {
        display: block !important;
        padding: 0 20px;
        height: 100%!important;
        opacity: 1!important;
    }

    &.two-columns {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;

        -webkit-column-gap: 60px;
        -moz-column-gap: 60px;
        column-gap: 60px;

        @include breakpoint(small only) {
            -webkit-column-count: 1;
            -moz-column-count: 2;
            column-count: 1;

            -webkit-column-gap: 0;
            -moz-column-gap: 0;
            column-gap: 0;
        }
    }

    &.has-label {
        position: relative;

        p {
            &:first-child {
                &:before {
                    position: absolute;
                    content: '';
                    display: inline-block;
                    height: 2px;
                    width: 30px;
                    background: $color-cyan;
                    left: -80px;
                    top: 10px;
                }
            }
        }

        &.toright {
            p:first-child {
                &:before {
                    right: -80px;
                    left: auto;
                }
            }
        }
    }
}


.heading {
    width: 160%;
    position: relative;
    left: -60%;

    &.floatright {
        right: -60%;
        left: auto;
    }

    &.simple {
        left: auto;
        right: auto;
        width: 100%;
    }

    @include breakpoint(medium only) {
        h1 {
            font-size: 50px;
            line-height: 56px
        }
    }

    // @include breakpoint(small only) {
    //     left: auto !important;
    //     right: auto !important;
    //     width: 100% !important;

    //     h1 {
    //         font-size: 30px;
    //         line-height: 36px;
    //         margin-top: 0 !important;
    //     }
    // }

    @media screen and (max-width:991px){
        left: auto !important;
        right: auto !important;
        width: 100% !important;

        h1 {
            font-size: 30px;
            line-height: 36px;
            margin-top: 0 !important;
        }
    }
}

.button,
.cta-button {
    display: inline-block;
    background: #fff;
    border: 1px solid #000;
    text-transform: uppercase;
    color: #fff;
    padding: 7px 40px;
    font-family: $font-din-condensed;
    font-size: 16px;
    margin-top: 20px;
    letter-spacing: 0.4px;
    transition: background 250ms;
    line-height: 24px;
    cursor: pointer;
    outline: none;

    &:hover {
        background: #D8D8D8;
        color: #000;
    }
}

.button {
    &.view-more {
        color: #008DCF;
        background: #F5F5F3;
        border: none;
        font-weight: bold;

        &:hover {
            background: darken(#F5F5F3, 5%);
        }
    }

}



// Overlay menu
.navigation {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: $color-cyan;
    opacity: 0;

    .menu {
        position: absolute;

        margin: 0;
        padding: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        list-style-type: none;
        @media screen and (max-width: 1023px) {
            padding: 60px 30px 0 30px;
            text-align: center;
            top: 0;
            left: 0;
            transform: translate(0, 0);
            width: 100%;
        }

        li {
            a {
                font-size: 50px;
                font-weight: 700;
                text-shadow: 1px 1px 0px rgba(#000, 0.2);
                transition: all 350ms;
                @include breakpoint(small only) {
                    font-size: 24px;
                }
           
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0 0 20px 20px;
            margin-bottom: 30px;
            border-bottom: solid 1px rgba(#fff, 0.2);
            @media screen and (max-width: 1023px) {
                padding: 0;
                border: 0;
            }

            li {
                a {
                    font-weight: 400;
                    font-size: 30px;
                    // color: rgba(#fff, 0.8);
                    color: #000;

                    @include breakpoint(small only) {
                        font-size: 20px;
                    }

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .close-btn {
        display: inline-block;
        position: absolute;
        top: 20px;
        right: 20px;
        width: 50px;

        @include breakpoint(small only) {
            width: 44px;
        }

        svg {
            width: 100%;

            path {
                fill: #fff;
            }
        }
    }
}

// Shame
.hauto {
    height: auto !important;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: .6px;
    border-left: 2px solid hsla(0, 0%, 100%, .08);

    padding: 13px 0;
}

.op1 {
    opacity: 1 !important;
}

.hp20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.hp0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.mt80 {
    margin-top: 80px;
}

.mt60 {
    margin-top: 60px;

    @include breakpoint(small only) {
        margin-top: 20px;
    }
}

.pt70 {
    padding-top: 70px;

    @include breakpoint(small only) {
        padding-top: 20px;
    }
}

.mb0 {
    margin-bottom: 0 !important;
}

.mb60 {
    margin-bottom: 60px !important;
}

.pt60 {
    padding-top: 60px;
}

.p0 {
    padding: 0 !important;
}


/* navigation arrows */
.page {
    position: relative;
}

.nav-arrow {
    position: fixed;
    top: 47%;
    transform: translateY(-50%);
}

.nav-arrow img {
    max-width: 60%;
}

.nav-arrow.left {
    left: 30px;
}

.nav-arrow.right {
    right: 0px;
}


.menu-button svg path {
    fill: #fff;
}
