/*speech understanding*/

.speech-understanding {

    audio {
         @media screen and (max-width: 400px) {
	     width: 200px;
	 }
    }

    .heading {
        left: -28.5%;
        z-index: 1;
        @media screen and (max-width: 767px) {
            max-width: 300px;
        }
        @media screen and (min-width:768px)and (max-width:991px) {
            margin-top: 0;
            margin-bottom: 30px;
        }
        @media screen and (min-width:768px)and (max-width:1023px) {
            left: 0;
        }
    }
    .columns.centered-col {
        @media screen and (max-width: 1366px) {
            float: left;
        }
    }
    img.signal {
        position: relative;
        left: -24%;
        max-width: 77%;
        @media screen and (max-width: 767px) {
            position: relative;
            left: auto;
            max-width: 100%;
        }
        @media screen and (min-width:768px)and (max-width:1023px) {
            left: 0;
            max-width: 60%;
        }
    }
    svg.svg-speech {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 60%;
        height: 270px;
        z-index: 0;
        @media screen and (max-width: 767px) {
            position: absolute;
            top: 0;
            right: 0;
            height: 60%;
            height: auto;
            max-width: 40%;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            position: absolute;
            top: 10px;
            right: 0;
            height: 60%;
            height: 42%;
            padding: 60px 10px 0px 0px;
        }
    }
    .custom-template {
        margin: 50px 50px 0px 50px;
        @media screen and (min-width: 768px) and (max-width: 991px) {
            margin: 70px 50px 0px 50px;
        }
    }
}

.speech-understanding h3.subtitle {
    color: #2ea7e7;
    margin-bottom: 20px;
}