.news-wrapper {
    margin-bottom: 60px;
    float: left;
}

.news-item {
    background: #fff;
    float: left;
    position: relative;

    @include breakpoint(small only) {}

}

.article-content {
    width: 60%;
    float: left;
    padding: 30px 40px 40px 40px;

    @include breakpoint(small only) {
        width: 100%;
        padding: 15px 20px 30px 20px;
    }

    h2 {
        font-family: $font-din-light;
        font-weight: 700;
        font-size: 18px;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 20px;
        color: #484949;
        padding-left: 60px;
        position: relative;

        &:before {
            display: inline-block;
            content: "";
            width: 40px;
            height: 3px;
            background: $color-cyan;
            top: 10px;
            position: absolute;
            left: 0;
        }

        @include breakpoint(small only) {
            padding-left: 0;
            padding-bottom: 10px;
            margin-top: 10px;

            &:before {
                left: 0;
                bottom: 0;
                top: auto;

            }
        }
    }

    p {
        line-height: 30px;
    }

    .date {
        color: $color-cyan;
        display: inline-block;
        width: 100%;
        text-align: right;
        font-size: 10px;
        font-family: $font-din-light;
        font-weight: 600;

        @include breakpoint(small only) {
            text-align: left;
        }
    }
}

.article-picture {
    width: 40%;
    overflow: hidden;
    position: absolute;
    height: 100%;
    right: 0;
    background-repeat: no-repeat;
    background-position: left center;

    @include breakpoint(small only) {
        width: 100%;
        height: 100px;
        position: relative;
    }

    img {
        min-width: 100%;
        min-height: 100%;
        // max-width: 9999px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media screen and (min-width:640px) and (max-width:1023px){
            object-fit: cover;
        }
    }
}
.article-picture.news-1{
    background-image: url("/assets/img/news/news-1.png");
}
.article-picture.news-2{
    background-image: url("/assets/img/news/news-2.png");
}


.news-item:nth-child(2n) {
    background: #F5F5F3;

    .article-content {
        float: right;
    }

    .article-picture {
        left: 0;
        right: auto;
    }

    .button {
        background: $medium-gray;

        &:hover {
            background: lighten(#F9F9F9, 5%);

        }
    }
}

/* NEWS PAGE */

.news-page {
    .news-wrapper {
        background: #fff;
    }

    .heading {
        left: 40px;
        margin-bottom: 30px;


        @media screen and (max-width: 767px) {
            padding: 0px 20px;
        }
        @media (min-width: 1200px) {
            left: -28%;
          }
        @media (min-width: 1024px) and (max-width: 1200px) {
            left: 40px;
        }
    }

    .news-item {
        border-top: 1px solid #F5F5F3;
    }

    h1.title,
    h2.light {
        @media screen and (max-width: 767px) {
            font-size: 30px;
        }
    }
}

.single-news{
    .heading{
        h1{
            font-weight: bold;
            font-size: 30px;
            line-height: normal;
        }
    }
    .text-block2{
        font-family: $font-din-light;
    }
    span{
        margin-top: 20px;
    }
}