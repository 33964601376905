.team-member {
    overflow: hidden;
    background: #fff;

    img {
        @include breakpoint(medium up) {
        }

        transition: opacity 500ms;
        -webkit-backface-visibility: hidden;
        max-height: 199px; 
        object-fit: cover;
        width: 100%;
        @media screen and (max-width: 639px){
            max-height: 100%;
            height: 250px;
        }
        &:hover{
            filter: brightness(105%);
        }
    }

    &:hover {
        img {
            opacity: 1;
            transition: opacity 250ms;
        }
    }

    &:hover {
        .member-caption {
            &:after {
                transform: translate(0, -5px);
            }
        }
    }

    @media screen and (min-width:640px) and (max-width: 1023px){
        min-height: 240px;
        max-height: 276px;
    }
    @media screen and (min-width:1024px){
        max-height: 290px;
    }
}

.team-block {
    position: relative;
    z-index: 100;
    margin-bottom: 40px;
    opacity: 0;
 
}

.member-caption {
    background: #fff;
    border: 1px solid #eee;
    padding: 15px 15px;
    position: relative;
    line-height: 0;

    &:after {
        content: "";
        display: inline-block;
        width: 25px;
        height: 2px;
        background: $color-cyan;
        margin-top: 10px;
        transition: all 500ms;
    }

    strong {
        text-transform: uppercase;
        color: #000;
        font-size: 14px;
        line-height: 14px;
        font-family: $font-din-bold;
        display: inline-block;
        width: 100%;
    }

    span {
        display: inline-block;
        width: 100%;
        font-size: 13px;
        line-height: 19px;
        position: relative;
        font-family:  $font-din-medium;
    }
    @media screen and (min-width:640px) and (max-width:991px){
        height: -webkit-fill-available;
    }

}

.test {
    color: red;
}

// No hidden content in team page
.no-accordeon {
    opacity: 1 !important;
    height: auto !important;
}

/* TEAM PAGE */

.team-page {
    h1 {
        text-transform: uppercase;

	&.title {
	    padding-left: 15px;
        }
    }

    .heading {
        line-height: normal;
        width: 320px;
        left: -103%;
        @media screen and (min-width:640px) and (max-width:767px){
            left: -85%;
        }
        @media screen and (min-width:768px) and (max-width:1023px){
            left: 0;
        }
        @media screen and (min-width:1024px) and (max-width:1056px){
            left: -125%;
        }

        @media screen and (min-width:1057px) and (max-width:1100px){
            left: -120%;
        }
        @media screen and (min-width:1101px) and (max-width:1118px){
            left: -108%;
        }
        @media screen and (min-width:1119px) and (max-width:1200px){
            left: -106%;
        }

    }
    .text-block{
        @media screen and (max-width: 767px) {
            padding: 0;
        }
    }
}