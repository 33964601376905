$box-min-height: 520px;

.project-box {
    position: relative;
    min-height: $box-min-height;
    
    p {
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        padding-right: 60px;
        position: relative;
        .homepage &, 
        .home & {
            padding-right: 20px;
        }
        @include breakpoint(medium only) {
            padding-right: 20px;
        }
        @include breakpoint(small only) {
            padding-right: 0px;
            text-align: left;
        }
        &:before {
            position: absolute;
            content:'';
            display: inline-block;
            height: 2px;
            width: 30px;
            background:$color-cyan;
            left:-80px;
            top: 10px;
        }
    }
    
    h1 {
        margin:40px 0 30px 0;
        white-space: nowrap;
    }

    &.singlep {
        padding-bottom: 60px;
        @include breakpoint(small only) {
            padding-bottom: 30px;
        }
    }
    &.has-larger-image {
        p {
            padding-right: 20px !important;
        }
    }
    @include breakpoint(small only) {
        min-height: 0;
    }
}

.projects-menu-wrapper {
    position: relative;
    min-height: $box-min-height;
    opacity: 0;
    @include breakpoint(small only) {
        min-height: 0;
    }
}

.projects-menu-content {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    @include breakpoint(small only) {
        position: relative;
        margin-bottom: 20px;
        float: left;
    }
    h2 {
        color: $color-cyan;
        font-size: 18px;
        text-transform: uppercase;
        font-family: $font-din-condensed;
        margin-bottom: 25px;
    }
}

.projects-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include breakpoint(small only) {
        padding: 0 0;
    }
    li {
        font-family: $font-din-light;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 0.6px;
        border-left: solid 2px rgba(#fff,0.08);
        padding: 13px 0;

        @include breakpoint(small only) {
            width: 50%;
            float: left;
            margin: 0;
            padding: 3px 6px 3px 0 !important;
            border:0;
        }

        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
        a {
            color: rgba(#000,0.9);
            display: inline-block;
            padding-left: 35px;
            position: relative;
            font-weight: 600;
            padding-top: 3px;
            width: 100%;
            transition: all 250ms;
            &:before {
                content:'';
                position: absolute;
                left: 0;
                width: 19px;
                height: 19px;
                border: solid 2px rgba(#000,0.12);
                border-radius:50%;
                display: inline-block;
                background:#111;
                left: -10px;
                top: 0;
                transition: all 200ms;
            }

            &:hover, &.active {
                color: #000;
                padding-left: 20px;
                &:before {
                    left: 0;
                    width: 19px;
                    height: 19px;
                    border: solid 3px #7196ed;
                    border-radius:50%;
                    display: inline-block;
                    background:#111;
                    left: -10px;
                    top: 0;
                }
            }
            @include breakpoint(small only) {
                border-left:0;
                padding: 7px 10px 7px 10px !important;
                font-size: 10px;
                &:before {
                    display: none !important
                }
            }
        }

        &.current-menu-item {
            a {
                color: #000;
                padding-left: 20px;
                &:before {
                    left: 0;
                    width: 19px;
                    height: 19px;
                    border: solid 3px #7196ed;
                    border-radius:50%;
                    display: inline-block;
                    background:#111;
                    left: -10px;
                    top: 0;
                }
            }
        }
    }
}


.toggle-info {
    position: absolute;
    bottom: 30px;
    left: 30px;
    text-transform: uppercase;
    color: rgba(#000,0.9);
    font-size: 10px;
    letter-spacing: 0.6px;
    font-weight: 600;
    line-height: 25px;
    &:before {
        content:'';
        display: inline-block;
        width: 35px;
        height: 35px;
        position: relative;
        filter: invert(100);
        top: 14px;
        margin-right: 10px;
        background-size: 99% auto;
        background-repeat: no-repeat;
        opacity: 0.4;
        transition: opacity 200ms;
    }
    &:focus{
        color: #000;
    }
    
    &.less {
        &:before {
            background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjU0IiB2aWV3Qm94PSIwIDAgNTQgNTQiIHdpZHRoPSI1NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyNyIgY3k9IjI3IiBmaWxsPSJub25lIiByPSIyNiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48cGF0aCBkPSJtMTMgMjZoMjh2MWgtMjh6IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==);
        }
    }

    &.more {
        &:before {
            background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjU0IiB2aWV3Qm94PSIwIDAgNTQgNTQiIHdpZHRoPSI1NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI2ZmZiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+PGNpcmNsZSBjeD0iMjYiIGN5PSIyNiIgcj0iMjYiIHN0cm9rZS13aWR0aD0iMiIvPjxwYXRoIGQ9Im0xMiAyNWgyOHYxaC0yOHoiLz48cGF0aCBkPSJtMTIgMjVoMjh2MWgtMjh6IiB0cmFuc2Zvcm09Im1hdHJpeCgwIDEgLTEgMCA1MS41IC0uNSkiLz48L2c+PC9zdmc+);
        }
    }

    &:hover {
        color: #000;
        &:before {
            opacity: 1;
        }
    }
}


.project-image {
    position: relative;
    top: 100px;
    padding: 0 30px;
    &.t60 {
        top: 60px;
    }
    @include breakpoint(medium only) {
        padding: 0 10px;
    }
    @include breakpoint(small only) {
        padding: 0;
        top: 0;
    }
    svg {
        width: 100%;
    }
}

svg {
    .border {
        stroke-width:1.5;
        fill: #1d1d1d;
    }
}

