.contact-form {
    height: auto;
    padding-bottom: 25px;
    float: right;
    width: 50%;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
    input,
    input:focus,
    textarea {
        background: #EBEFF1;
        border: 0;
        color: #000;
        margin-bottom: 5px;
        font-size: 14px;
        font-family: $font-din-light;
    }

    textarea {
        height: 120px;
    }

    input.wpcf7-not-valid {
        background: transparent;
        border: 1px solid #ff0000;
    }

    .cta-button {
        background: $color-cyan;
        padding: 7px 35px;
        border: none;

        &:hover {
            background: darken($color-cyan, 10%);
            color: #fff;
        }
    }

    .text-block {
        margin-bottom: 20px;
        padding: 0 20px;
    }
}

.map {
    width: 100%;
    opacity: 0;
    background: #666;
    height: 350px;
    position: relative;
    z-index: 102;
}

.address-block {
    position: relative;

    @include breakpoint(small only) {
        height: auto !important;
    }
}

.contact-page .visible-mobile {
    display: none;
}

.address-wrapper {
    position: absolute;
    opacity: 0;
    bottom: 0;
    width: 100%;

    background: none;


    padding: 0px 30px 30px 60px;

    @include breakpoint(small only) {
        position: relative;
        padding: 20px;
        background: #000;
    }


    h2 {
        text-transform: uppercase;
        font-size: 16px;
        color: #000;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 10px;
        margin-top: 30px;
        
        @media screen and (max-width: 767px) {
            margin-top: 0px;
        }

        &:after {
            content: '';
            background: $color-cyan;
            height: 2px;
            width: 30px;
            display: inline-block;
            bottom: 0;
            left: 0;
            position: absolute;
        }
    }

    strong {
        display: inline-block;
        width: 100%;
        font-size: 14px;
    }

    span {
        display: inline-block;
        width: 100%;
        font-size: 14px;
    }
    p{
       margin-bottom: 10px;
    }
}

/* CONTACT PAGE */


.contact-page {
    position: relative;
    padding: 0 30px 6px 60px;

    .address-block {
        height: 100%;
        width: 50%;
        height: 415px !important;
        background: none !important;

        @media screen and (max-width: 767px) {
            position: relative;
            height: auto !important;
            width: 100%;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px){
            height: 425px!important;
        }

        .address-wrapper {
            position: absolute;
            font-weight: bold;
            padding: 0 30px 6px 60px;
            background: none;

            @media screen and (max-width: 767px) {
                padding: 0px 30px 6px 10px;
                position: relative;

            }

            h2 {
                font-weight: bold;
            }

            p {
                font-size: 14px;
            }
        }


    }


    .box {
        padding: 0;
        padding-right: 30px;

        @media screen and (max-width: 767px) {
            padding: 0;
        }
    }

    .heading {
        width: 160%;
        display: inline-flex;
        margin-top: 80px;
        left: -44%;

        @media screen and (max-width: 767px) {
            left: 10px !important;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        @media screen and (min-width: 768px) and (max-width:830px){
            left:-60%!important;
            // margin-top: 40px;
            width: 400px!important;
            font-size:30px;
        }
        @media screen and (min-width: 831px) and (max-width:880px){
            left:-55%!important;
            width: 400px!important;
        }
        @media screen and (min-width: 881px) and (max-width:1023px){
            left: -50%!important;
            width: 400px!important;
        }
        @media screen and (min-width: 1023px) and (max-width:1120px){
            left: -50%!important;
        }

        h1.title{
            @media screen and (min-width: 768px) and (max-width:991px){
                padding-left: 25px;
                font-size: 30px;
                line-height: normal;
            }
  
        }
        h2.light{
            @media screen and (min-width: 768px) and (max-width:991px){
                font-size: 30px;
                line-height: normal;
            }

        }
   
    }

    .text-block2 {
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 0;
        padding: 0 20px;

        @media screen and (max-width: 767px) {
            margin-top: 0;
        }
    }
    .visible-mobile {
        display: none;

        @media screen and (max-width: 767px) {
            display: block;
        }
    }

    .alert-message {

        border: 1px solid #f7e700;
        margin: 20px 0px;
        padding: 10px;
        text-align: center;
    }

}



.box.gradient.shadow.mb0 {

    &:before,
    &:after {
        display: table;
        clear: both;
        content: "";
    }
}

section.page.contact-page {
    padding: 40px 0 0px;
    
    @media screen and (max-width: 767px) {
        padding: 0px;
    }
}

.contact-form .cta-button,
.team-page .cta-button {
    background: #1BA1E4;
    padding: 7px 35px;
    color: #fff;
    border: none;
    line-height: 24px;
    @media screen and (max-width: 767px){
        width: 100%;
        text-align: center;
    }
}

.contact-form .cta-button:hover,
.team-page .cta-button:hover {
    background: #1BA1E4;
    color: #fff;

}

.contact-form .text-block {
    margin-bottom: 20px;
    padding: 0 20px
}