.footer {
    background: $footer-color;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 105;
    display: none;

    .row.footer-row {
        max-width: 100%;
        padding: 0px 70px;



        @media (min-width: 1200px) {
            padding: 0px 20px;
        }

        @media (min-width: 1600px) {
            max-width: 100%;
            padding: 0px 70px;
        }

        @media (min-width: 1024px) and (max-width: 1200px) {
            padding: 0px 20px;
        }

        @media (max-width: 1023px) {
            padding: 0px 20px;
        }

        &.empty {
            height: 30px;
            width: 100%;
            background: $footer-bottom-color;

            @media(max-width: 575px) {
                display: none
                
            }
        }
    }

    .logo {
        padding: 40px 0 0 0;
        display: block;
        opacity: 1;
        position: absolute;
        padding: 0px;
        bottom: 30px;
        @media(max-width: 575px) {
            bottom: 0px;
        }
        img {
            max-height:75px;
            height: auto;
            width: 225px;
            @media(max-width: 797px) {
                max-width: 70%;
                right: 0;
            }
            @media (min-width: 1200px) {
                width: 180px;
            }
            @media (min-width: 1600px) {
                width: 225px;
            }
    
            @media (min-width: 1024px) and (max-width: 1200px) {
                max-width: 180px;
            }
            @media (max-width: 1023px) {
                max-width: 150px;
            }
        }

        @media (min-width: 1200px) {
            right: 55px;
        }

        @media (min-width: 1600px) {
            right: 55px;
            width: 225px;
        }

        @media (min-width: 1024px) and (max-width: 1200px) {
            right: 50px;
        }

        @media (max-width: 1023px) {
            right: 20px;
        }

        svg {
            width: 100%;
        }
    }


    .footer-menu {
        margin: 5px 0 0 0;
        padding: 0;
        list-style-type: none;
        border-bottom: solid 2px rgba(#188ddd, 0.1);
        position: relative;

        @media (max-width: 1023px) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        li {
            display: inline-block;
            padding-right: 15px;
            font-weight: 500;
            font-size: 18px;
            text-transform: uppercase;


            @media (min-width: 1200px) {
                font-size: 14px;
                padding-right: 9px;
            }

            @media (min-width: 1600px) {
                padding-right: 15px;
                font-weight: 500;
            }

            @media (min-width: 1024px) and (max-width: 1200px) {
                font-size: 14px;
                padding-right: 7px;
            }

            @media (max-width: 1023px) {
                font-size: 14px;
                padding-right: 7px;
            }

            a {
                display: inline-block;
                color: #000;
                height: 32px;
                border-bottom: solid 2px transparent;
                position: relative;
                top: 2px;
                transition: all 250ms;

                &:hover {
                    color: rgba(#000, 0.7);
                    border-color: $color-cyan;
                }

                &.active {
                    color: #408ec4;
                    border-bottom: 3px solid $color-cyan;
                }
            }

            &.current-menu-item {
                a {
                    border-color: $color-cyan;
                    color: $color-cyan;
                }
            }
        }
    }

    .copyright {
        font-family: $font-din-light;
        text-align: right;
        list-style: none;
        padding: 10px 0px;
        margin: 0px;
        color: #000;
    

        @media (max-width: 797px) {
            width: 100%;
            font-size: 12px;
        }

        @media (min-width: 1200px) {
            font-size: 14px;
        }

        @media (min-width: 1024px) and (max-width: 1200px) {
            font-size: 14px;
        }

        @media (max-width: 1023px) {
            font-size: 14px;
        }

    }
    .hide-xs-mobile{
        @media (max-width: 480px){
            display: none;
        }

    }

}