/* home*/

.home-template {
    img {
        max-width: 56%;
	@media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }
    h3.subtitle {
        margin-left: 0;
        margin-bottom: 20px;
        font-size: 1.9rem;
    }
    .columns.centered-col {
        @media screen and (max-width: 1366px) {
            float: left;
        }
    }
    .heading {
        left: -31%;
        @media screen and (min-width: 767px) and (max-width: 1023px) {
            left: 0;
            width: 65%!important;
        }
        @media screen and (min-width: 1078px) and (max-width:1090px) {
            left: -35%;
        }
        @media screen and (min-width: 1090px) and (max-width:1135px) {
            left: -34%;
        }
        @media screen and (min-width: 1136px) and (max-width:1200px) {
            left: -31%;
        }
    }
    .home-svg {
        display: block;
        float: right;
        padding: 15px;
        @media screen and (max-width: 767px) {
            float: none;
            display: block;
            margin: 0 auto;
        }
        @media screen and (min-width:1078px) and (max-width:1200px) {
            padding: 15px 15px 15px 120px;
        }
    }
    .img-row {
        position: absolute;
        top: 0;
        right: 0;
        @media screen and (max-width: 767px) {
            position: relative;
            top: 0;
        }
    }
    .custom-template {
        margin: 40px 50px 0px 50px;
    }
}

.home-template .project-image {
    top: 0;
    padding: 20px 0px 0px 0px;
}