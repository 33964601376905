@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #f7f7f7;
  font-family: "DINPro-Medium", "futura-pt", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #bdbdbd;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DINPro-Light", "futura-pt", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #000;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: black; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #0a0a0a;
  color: #bdbdbd;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #bdbdbd;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #bdbdbd;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #353535; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #bdbdbd;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #bdbdbd;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: #f7f7f7; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

@font-face {
  font-family: 'DINPro-Bold';
  src: url("../fonts/DINPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Bold.otf") format("opentype"), url("../fonts/DINPro-Bold.woff") format("woff"), url("../fonts/DINPro-Bold.ttf") format("truetype"), url("../fonts/DINPro-Bold.svg#DINPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'DINPro-Light';
  src: url("../fonts/DINPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Light.otf") format("opentype"), url("../fonts/DINPro-Light.woff") format("woff"), url("../fonts/DINPro-Light.ttf") format("truetype"), url("../fonts/DINPro-Light.svg#DINPro-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'DINPro-Medium';
  src: url("../fonts/DINPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Medium.otf") format("opentype"), url("../fonts/DINPro-Medium.woff") format("woff"), url("../fonts/DINPro-Medium.ttf") format("truetype"), url("../fonts/DINPro-Medium.svg#DINPro-Medium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'EurostileBQ-BoldExtended';
  src: url("../fonts/EurostileBQ-BoldExtended.eot?#iefix") format("embedded-opentype"), url("../fonts/EurostileBQ-BoldExtended.otf") format("opentype"), url("../fonts/EurostileBQ-BoldExtended.woff") format("woff"), url("../fonts/EurostileBQ-BoldExtended.ttf") format("truetype"), url("../fonts/EurostileBQ-BoldExtended.svg#EurostileBQ-BoldExtended") format("svg");
  font-weight: normal;
  font-style: normal; }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #666;
  opacity: 0.5; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666;
  opacity: 0.5; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666;
  opacity: 1;
  opacity: 0.5; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #666;
  opacity: 0.5; }

html {
  height: 100%; }
  html.noscroll {
    overflow: hidden; }

body {
  height: calc(100%);
  padding-bottom: 160px;
  background: #f7f7f7;
  color: #000;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  font-weight: normal; }
  body.noscroll {
    overflow: hidden; }

h1 {
  text-transform: uppercase;
  font-size: 70px;
  color: #484949;
  font-family: "DINPro-Medium", sans-serif;
  line-height: 70px;
  opacity: 0; }

p {
  font-family: "DINPro-Light", sans-serif;
  font-size: 15px; }

.box {
  padding: 30px;
  opacity: 0; }
  .box.gradient {
    background: #fff; }
  .box.shadow {
    box-shadow: -30px 30px 68px rgba(143, 143, 143, 0.5);
    margin-bottom: 70px; }
    @media screen and (max-width: 39.9375em) {
      .box.shadow {
        margin-bottom: 40px;
        box-shadow: 0 5px 10px rgba(143, 143, 143, 0.2); } }

.box.gradient.shadow.mb0:before,
.box.gradient.shadow.mb0:after {
  display: table;
  clear: both;
  content: ""; }

#top-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 320px;
  background-color: #fff;
  z-index: -2; }

section.page {
  padding: 40px 0 170px 0; }
  .page-template-contact section.page,
  .contact section.page {
    padding: 40px 0 110px 0; }
    @media screen and (max-width: 39.9375em) {
      .page-template-contact section.page,
      .contact section.page {
        padding: 0 0 0px 0; } }
  @media screen and (max-width: 39.9375em) {
    section.page {
      padding: 0 0 20px 0; } }

.wave {
  position: absolute;
  z-index: 0;
  background-size: contain;
  height: 100%;
  width: 100%;
  max-width: 100%;
  top: 37px;
  max-width: 1600px;
  max-height: 564px;
  background-image: url(../img/bg-template.png);
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
  margin: auto;
  left: 0;
  right: 0; }
  @media screen and (max-width: 39.9375em) {
    .wave {
      display: none; } }

.bg-template {
  position: absolute;
  z-index: 0;
  background-size: contain;
  height: 58%;
  width: 100%;
  max-width: 100%;
  background-image: url(../img/bg-template.png);
  background-repeat: no-repeat;
  background-position: center center; }
  @media screen and (max-width: 39.9375em) {
    .bg-template {
      display: none; } }

.slide {
  display: none; }

.text-block {
  font-size: 14px;
  overflow: hidden;
  position: relative;
  display: block; }
  @media screen and (max-width: 39.9375em) {
    .text-block {
      display: block !important;
      padding: 0 20px;
      height: 100% !important;
      opacity: 1 !important; } }
  .text-block.two-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 60px;
    -moz-column-gap: 60px;
    column-gap: 60px; }
    @media screen and (max-width: 39.9375em) {
      .text-block.two-columns {
        -webkit-column-count: 1;
        -moz-column-count: 2;
        column-count: 1;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0; } }
  .text-block.has-label {
    position: relative; }
    .text-block.has-label p:first-child:before {
      position: absolute;
      content: '';
      display: inline-block;
      height: 2px;
      width: 30px;
      background: #188ddd;
      left: -80px;
      top: 10px; }
    .text-block.has-label.toright p:first-child:before {
      right: -80px;
      left: auto; }

.heading {
  width: 160%;
  position: relative;
  left: -60%; }
  .heading.floatright {
    right: -60%;
    left: auto; }
  .heading.simple {
    left: auto;
    right: auto;
    width: 100%; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .heading h1 {
      font-size: 50px;
      line-height: 56px; } }
  @media screen and (max-width: 991px) {
    .heading {
      left: auto !important;
      right: auto !important;
      width: 100% !important; }
      .heading h1 {
        font-size: 30px;
        line-height: 36px;
        margin-top: 0 !important; } }

.button,
.cta-button {
  display: inline-block;
  background: #fff;
  border: 1px solid #000;
  text-transform: uppercase;
  color: #fff;
  padding: 7px 40px;
  font-family: "din-condensed", sans-serif;
  font-size: 16px;
  margin-top: 20px;
  letter-spacing: 0.4px;
  transition: background 250ms;
  line-height: 24px;
  cursor: pointer;
  outline: none; }
  .button:hover,
  .cta-button:hover {
    background: #D8D8D8;
    color: #000; }

.button.view-more {
  color: #008DCF;
  background: #F5F5F3;
  border: none;
  font-weight: bold; }
  .button.view-more:hover {
    background: #e9e9e5; }

.navigation {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #188ddd;
  opacity: 0; }
  .navigation .menu {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style-type: none; }
    @media screen and (max-width: 1023px) {
      .navigation .menu {
        padding: 60px 30px 0 30px;
        text-align: center;
        top: 0;
        left: 0;
        transform: translate(0, 0);
        width: 100%; } }
    .navigation .menu li a {
      font-size: 50px;
      font-weight: 700;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
      transition: all 350ms; }
      @media screen and (max-width: 39.9375em) {
        .navigation .menu li a {
          font-size: 24px; } }
    .navigation .menu ul {
      list-style-type: none;
      margin: 0;
      padding: 0 0 20px 20px;
      margin-bottom: 30px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
      @media screen and (max-width: 1023px) {
        .navigation .menu ul {
          padding: 0;
          border: 0; } }
      .navigation .menu ul li a {
        font-weight: 400;
        font-size: 30px;
        color: #000; }
        @media screen and (max-width: 39.9375em) {
          .navigation .menu ul li a {
            font-size: 20px; } }
        .navigation .menu ul li a:hover {
          opacity: 0.5; }
  .navigation .close-btn {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px; }
    @media screen and (max-width: 39.9375em) {
      .navigation .close-btn {
        width: 44px; } }
    .navigation .close-btn svg {
      width: 100%; }
      .navigation .close-btn svg path {
        fill: #fff; }

.hauto {
  height: auto !important;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: .6px;
  border-left: 2px solid rgba(255, 255, 255, 0.08);
  padding: 13px 0; }

.op1 {
  opacity: 1 !important; }

.hp20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.hp0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.mt80 {
  margin-top: 80px; }

.mt60 {
  margin-top: 60px; }
  @media screen and (max-width: 39.9375em) {
    .mt60 {
      margin-top: 20px; } }

.pt70 {
  padding-top: 70px; }
  @media screen and (max-width: 39.9375em) {
    .pt70 {
      padding-top: 20px; } }

.mb0 {
  margin-bottom: 0 !important; }

.mb60 {
  margin-bottom: 60px !important; }

.pt60 {
  padding-top: 60px; }

.p0 {
  padding: 0 !important; }

/* navigation arrows */
.page {
  position: relative; }

.nav-arrow {
  position: fixed;
  top: 47%;
  transform: translateY(-50%); }

.nav-arrow img {
  max-width: 60%; }

.nav-arrow.left {
  left: 30px; }

.nav-arrow.right {
  right: 0px; }

.menu-button svg path {
  fill: #fff; }

.header {
  padding: 0 0 40px 0;
  background: #f2f2f0; }
  .header .logo {
    width: 155px;
    display: inline-block;
    padding-left: 20px; }
    .header .logo svg {
      width: 100%; }
    @media screen and (max-width: 39.9375em) {
      .header .logo img {
        height: auto; } }
  @media screen and (max-width: 39.9375em) {
    .header {
      padding: 0 0 10px 0; } }

.menu-button {
  width: 22px;
  display: inline-block;
  position: relative;
  top: 20px; }
  .menu-button svg {
    width: 100%; }
  @media screen and (max-width: 39.9375em) {
    .menu-button {
      width: 33px;
      top: 15px;
      right: 20px;
      width: 28px; } }

/*NAVBAR DESKTOP*/
.header .visible-xs {
  display: none;
  background: #000;
  height: 63px; }

.header .visible-large {
  background: #282727; }

.header ul.desktop-nav {
  margin: 0; }

@media screen and (max-width: 767px) {
  .header {
    padding: 0px; } }

.desktop-nav li.current-menu-item a,
.desktop-nav li a.active {
  color: #1d76b5; }

.header .desktop-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: 100%;
  padding-right: 40px; }
  .header .desktop-nav .nav-item {
    font-family: "DINPro-Light", sans-serif;
    justify-content: space-between;
    color: #fff;
    font-weight: 500;
    font-size: 21px;
    padding: 0px 15px;
    justify-content: flex-end;
    text-transform: uppercase; }
    @media (min-width: 1200px) {
      .header .desktop-nav .nav-item {
        font-size: 16px;
        padding: 0 10px; } }
    @media (min-width: 1024px) and (max-width: 1200px) {
      .header .desktop-nav .nav-item {
        font-size: 14px;
        padding: 0 5px; } }
  .header .desktop-nav a {
    color: #fff;
    transition: border-bottom 0.6s;
    border-bottom: 1px solid transparent; }
    .header .desktop-nav a:hover {
      border-bottom: 1px solid #188ddd; }

.visible-large {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }

.desktop-logo-container {
  width: auto;
  height: auto;
  padding-left: 40px;
  padding-bottom: 15px; }
  .desktop-logo-container .logo-desktop img {
    width: 225px;
    height: 75px; }
    @media (min-width: 1024px) and (max-width: 1200px) {
      .desktop-logo-container .logo-desktop img {
        width: auto;
        max-width: 180px;
        height: auto; } }
  @media (min-width: 1024px) and (max-width: 1200px) {
    .desktop-logo-container {
      padding-left: 20px;
      padding-bottom: 10px; } }

.nav-items {
  flex-grow: 1;
  justify-content: flex-end; }

.navigation .menu .nav-item a {
  color: #fff; }
  .navigation .menu .nav-item a:hover {
    color: white; }

.navigation .close-btn svg path {
  fill: #fff; }

.project-box {
  position: relative;
  min-height: 520px; }
  .project-box p {
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    padding-right: 60px;
    position: relative; }
    .homepage .project-box p,
    .home .project-box p {
      padding-right: 20px; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .project-box p {
        padding-right: 20px; } }
    @media screen and (max-width: 39.9375em) {
      .project-box p {
        padding-right: 0px;
        text-align: left; } }
    .project-box p:before {
      position: absolute;
      content: '';
      display: inline-block;
      height: 2px;
      width: 30px;
      background: #188ddd;
      left: -80px;
      top: 10px; }
  .project-box h1 {
    margin: 40px 0 30px 0;
    white-space: nowrap; }
  .project-box.singlep {
    padding-bottom: 60px; }
    @media screen and (max-width: 39.9375em) {
      .project-box.singlep {
        padding-bottom: 30px; } }
  .project-box.has-larger-image p {
    padding-right: 20px !important; }
  @media screen and (max-width: 39.9375em) {
    .project-box {
      min-height: 0; } }

.projects-menu-wrapper {
  position: relative;
  min-height: 520px;
  opacity: 0; }
  @media screen and (max-width: 39.9375em) {
    .projects-menu-wrapper {
      min-height: 0; } }

.projects-menu-content {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0; }
  @media screen and (max-width: 39.9375em) {
    .projects-menu-content {
      position: relative;
      margin-bottom: 20px;
      float: left; } }
  .projects-menu-content h2 {
    color: #188ddd;
    font-size: 18px;
    text-transform: uppercase;
    font-family: "din-condensed", sans-serif;
    margin-bottom: 25px; }

.projects-menu {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  @media screen and (max-width: 39.9375em) {
    .projects-menu {
      padding: 0 0; } }
  .projects-menu li {
    font-family: "DINPro-Light", sans-serif;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.6px;
    border-left: solid 2px rgba(255, 255, 255, 0.08);
    padding: 13px 0; }
    @media screen and (max-width: 39.9375em) {
      .projects-menu li {
        width: 50%;
        float: left;
        margin: 0;
        padding: 3px 6px 3px 0 !important;
        border: 0; } }
    .projects-menu li:first-child {
      padding-top: 0; }
    .projects-menu li:last-child {
      padding-bottom: 0; }
    .projects-menu li a {
      color: rgba(0, 0, 0, 0.9);
      display: inline-block;
      padding-left: 35px;
      position: relative;
      font-weight: 600;
      padding-top: 3px;
      width: 100%;
      transition: all 250ms; }
      .projects-menu li a:before {
        content: '';
        position: absolute;
        left: 0;
        width: 19px;
        height: 19px;
        border: solid 2px rgba(0, 0, 0, 0.12);
        border-radius: 50%;
        display: inline-block;
        background: #111;
        left: -10px;
        top: 0;
        transition: all 200ms; }
      .projects-menu li a:hover, .projects-menu li a.active {
        color: #000;
        padding-left: 20px; }
        .projects-menu li a:hover:before, .projects-menu li a.active:before {
          left: 0;
          width: 19px;
          height: 19px;
          border: solid 3px #7196ed;
          border-radius: 50%;
          display: inline-block;
          background: #111;
          left: -10px;
          top: 0; }
      @media screen and (max-width: 39.9375em) {
        .projects-menu li a {
          border-left: 0;
          padding: 7px 10px 7px 10px !important;
          font-size: 10px; }
          .projects-menu li a:before {
            display: none !important; } }
    .projects-menu li.current-menu-item a {
      color: #000;
      padding-left: 20px; }
      .projects-menu li.current-menu-item a:before {
        left: 0;
        width: 19px;
        height: 19px;
        border: solid 3px #7196ed;
        border-radius: 50%;
        display: inline-block;
        background: #111;
        left: -10px;
        top: 0; }

.toggle-info {
  position: absolute;
  bottom: 30px;
  left: 30px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.9);
  font-size: 10px;
  letter-spacing: 0.6px;
  font-weight: 600;
  line-height: 25px; }
  .toggle-info:before {
    content: '';
    display: inline-block;
    width: 35px;
    height: 35px;
    position: relative;
    filter: invert(100);
    top: 14px;
    margin-right: 10px;
    background-size: 99% auto;
    background-repeat: no-repeat;
    opacity: 0.4;
    transition: opacity 200ms; }
  .toggle-info:focus {
    color: #000; }
  .toggle-info.less:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjU0IiB2aWV3Qm94PSIwIDAgNTQgNTQiIHdpZHRoPSI1NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyNyIgY3k9IjI3IiBmaWxsPSJub25lIiByPSIyNiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48cGF0aCBkPSJtMTMgMjZoMjh2MWgtMjh6IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==); }
  .toggle-info.more:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjU0IiB2aWV3Qm94PSIwIDAgNTQgNTQiIHdpZHRoPSI1NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI2ZmZiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+PGNpcmNsZSBjeD0iMjYiIGN5PSIyNiIgcj0iMjYiIHN0cm9rZS13aWR0aD0iMiIvPjxwYXRoIGQ9Im0xMiAyNWgyOHYxaC0yOHoiLz48cGF0aCBkPSJtMTIgMjVoMjh2MWgtMjh6IiB0cmFuc2Zvcm09Im1hdHJpeCgwIDEgLTEgMCA1MS41IC0uNSkiLz48L2c+PC9zdmc+); }
  .toggle-info:hover {
    color: #000; }
    .toggle-info:hover:before {
      opacity: 1; }

.project-image {
  position: relative;
  top: 100px;
  padding: 0 30px; }
  .project-image.t60 {
    top: 60px; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .project-image {
      padding: 0 10px; } }
  @media screen and (max-width: 39.9375em) {
    .project-image {
      padding: 0;
      top: 0; } }
  .project-image svg {
    width: 100%; }

svg .border {
  stroke-width: 1.5;
  fill: #1d1d1d; }

.footer {
  background: #f7f7f7;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 105;
  display: none; }
  .footer .row.footer-row {
    max-width: 100%;
    padding: 0px 70px; }
    @media (min-width: 1200px) {
      .footer .row.footer-row {
        padding: 0px 20px; } }
    @media (min-width: 1600px) {
      .footer .row.footer-row {
        max-width: 100%;
        padding: 0px 70px; } }
    @media (min-width: 1024px) and (max-width: 1200px) {
      .footer .row.footer-row {
        padding: 0px 20px; } }
    @media (max-width: 1023px) {
      .footer .row.footer-row {
        padding: 0px 20px; } }
    .footer .row.footer-row.empty {
      height: 30px;
      width: 100%;
      background: #dddddd; }
      @media (max-width: 575px) {
        .footer .row.footer-row.empty {
          display: none; } }
  .footer .logo {
    padding: 40px 0 0 0;
    display: block;
    opacity: 1;
    position: absolute;
    padding: 0px;
    bottom: 30px; }
    @media (max-width: 575px) {
      .footer .logo {
        bottom: 0px; } }
    .footer .logo img {
      max-height: 75px;
      height: auto;
      width: 225px; }
      @media (max-width: 797px) {
        .footer .logo img {
          max-width: 70%;
          right: 0; } }
      @media (min-width: 1200px) {
        .footer .logo img {
          width: 180px; } }
      @media (min-width: 1600px) {
        .footer .logo img {
          width: 225px; } }
      @media (min-width: 1024px) and (max-width: 1200px) {
        .footer .logo img {
          max-width: 180px; } }
      @media (max-width: 1023px) {
        .footer .logo img {
          max-width: 150px; } }
    @media (min-width: 1200px) {
      .footer .logo {
        right: 55px; } }
    @media (min-width: 1600px) {
      .footer .logo {
        right: 55px;
        width: 225px; } }
    @media (min-width: 1024px) and (max-width: 1200px) {
      .footer .logo {
        right: 50px; } }
    @media (max-width: 1023px) {
      .footer .logo {
        right: 20px; } }
    .footer .logo svg {
      width: 100%; }
  .footer .footer-menu {
    margin: 5px 0 0 0;
    padding: 0;
    list-style-type: none;
    border-bottom: solid 2px rgba(24, 141, 221, 0.1);
    position: relative; }
    @media (max-width: 1023px) {
      .footer .footer-menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around; } }
    .footer .footer-menu li {
      display: inline-block;
      padding-right: 15px;
      font-weight: 500;
      font-size: 18px;
      text-transform: uppercase; }
      @media (min-width: 1200px) {
        .footer .footer-menu li {
          font-size: 14px;
          padding-right: 9px; } }
      @media (min-width: 1600px) {
        .footer .footer-menu li {
          padding-right: 15px;
          font-weight: 500; } }
      @media (min-width: 1024px) and (max-width: 1200px) {
        .footer .footer-menu li {
          font-size: 14px;
          padding-right: 7px; } }
      @media (max-width: 1023px) {
        .footer .footer-menu li {
          font-size: 14px;
          padding-right: 7px; } }
      .footer .footer-menu li a {
        display: inline-block;
        color: #000;
        height: 32px;
        border-bottom: solid 2px transparent;
        position: relative;
        top: 2px;
        transition: all 250ms; }
        .footer .footer-menu li a:hover {
          color: rgba(0, 0, 0, 0.7);
          border-color: #188ddd; }
        .footer .footer-menu li a.active {
          color: #408ec4;
          border-bottom: 3px solid #188ddd; }
      .footer .footer-menu li.current-menu-item a {
        border-color: #188ddd;
        color: #188ddd; }
  .footer .copyright {
    font-family: "DINPro-Light", sans-serif;
    text-align: right;
    list-style: none;
    padding: 10px 0px;
    margin: 0px;
    color: #000; }
    @media (max-width: 797px) {
      .footer .copyright {
        width: 100%;
        font-size: 12px; } }
    @media (min-width: 1200px) {
      .footer .copyright {
        font-size: 14px; } }
    @media (min-width: 1024px) and (max-width: 1200px) {
      .footer .copyright {
        font-size: 14px; } }
    @media (max-width: 1023px) {
      .footer .copyright {
        font-size: 14px; } }
  @media (max-width: 480px) {
    .footer .hide-xs-mobile {
      display: none; } }

/*common to templates*/
.predictive-analysis h3.subtitle, .glas-ai-brain h3.subtitle, .home-template h3.subtitle {
  color: #2ea7e7;
  padding: 0px 30px 0px 0px; }

.predictive-analysis .heading, .glas-ai-brain .heading, .home-template .heading, .speech-understanding .heading, .contact-page .heading, .team-page .heading, .news-page .heading {
  margin-top: 30px;
  width: 100%;
  position: relative; }

.predictive-analysis h1.title, .glas-ai-brain h1.title, .home-template h1.title, .speech-understanding h1.title, .contact-page h1.title, .team-page h1.title, .news-page h1.title {
  font-size: 35px;
  line-height: normal;
  font-weight: bold;
  padding-left: 30px;
  width: auto;
  display: inline-block; }

.predictive-analysis p, .home-template p, .glas-ai-brain p, .speech-understanding p, .team-page p {
  text-indent: 15px;
  font-size: 15px; }

.predictive-analysis h2.light, .glas-ai-brain h2.light, .home-template h2.light, .speech-understanding h2.light, .contact-page h2.light, .team-page h2.light, .news-page h2.light {
  font-size: 35px;
  line-height: normal;
  vertical-align: top;
  display: inline; }
  @media screen and (max-width: 767px) {
    .predictive-analysis h2.light, .glas-ai-brain h2.light, .home-template h2.light, .speech-understanding h2.light, .contact-page h2.light, .team-page h2.light, .news-page h2.light {
      font-size: 30px;
      padding-right: 10px; } }

.predictive-analysis .box, .glas-ai-brain .box, .speech-understanding .box, .home-template .box {
  padding-bottom: 70px; }
  @media screen and (max-width: 767px) {
    .predictive-analysis .box, .glas-ai-brain .box, .speech-understanding .box, .home-template .box {
      padding-bottom: 30px; } }

.hidden-large {
  display: none; }

.audio-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }
  .audio-wrapper span {
    display: flex;
    flex-grow: 1;
    font-family: "DINPro-Light", sans-serif; }
  .audio-wrapper audio {
    margin-bottom: 6px; }
  .audio-wrapper.wmb {
    margin-bottom: 12px; }

audio {
  background: #373737;
  width: 270px;
  height: 40px; }
  @media screen and (min-width: 640px) and (max-width: 767px) {
    audio {
      width: 200px; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    audio {
      width: 250px; } }
  @media screen and (min-width: 1023px) and (max-width: 1200px) {
    audio {
      width: 250px; } }

audio::-webkit-media-controls-enclosure {
  background: transparent;
  border-radius: 0; }

audio::-webkit-media-controls-mute-button,
audio::-webkit-media-controls-current-time-display {
  color: #fff; }

audio::-webkit-media-controls-play-button,
audio::-webkit-internal-media-controls-segmented-track,
audio::-webkit-media-controls-timeline,
audio::-webkit-media-controls-mute-button {
  filter: invert(1000); }

audio::-webkit-media-controls-time-remaining-display {
  color: #fff; }

audio::-webkit-media-controls-enclosure {
  min-height: 40px;
  max-height: 40px; }

/* home*/
.home-template img {
  max-width: 56%; }
  @media screen and (max-width: 767px) {
    .home-template img {
      max-width: 100%; } }

.home-template h3.subtitle {
  margin-left: 0;
  margin-bottom: 20px;
  font-size: 1.9rem; }

@media screen and (max-width: 1366px) {
  .home-template .columns.centered-col {
    float: left; } }

.home-template .heading {
  left: -31%; }
  @media screen and (min-width: 767px) and (max-width: 1023px) {
    .home-template .heading {
      left: 0;
      width: 65% !important; } }
  @media screen and (min-width: 1078px) and (max-width: 1090px) {
    .home-template .heading {
      left: -35%; } }
  @media screen and (min-width: 1090px) and (max-width: 1135px) {
    .home-template .heading {
      left: -34%; } }
  @media screen and (min-width: 1136px) and (max-width: 1200px) {
    .home-template .heading {
      left: -31%; } }

.home-template .home-svg {
  display: block;
  float: right;
  padding: 15px; }
  @media screen and (max-width: 767px) {
    .home-template .home-svg {
      float: none;
      display: block;
      margin: 0 auto; } }
  @media screen and (min-width: 1078px) and (max-width: 1200px) {
    .home-template .home-svg {
      padding: 15px 15px 15px 120px; } }

.home-template .img-row {
  position: absolute;
  top: 0;
  right: 0; }
  @media screen and (max-width: 767px) {
    .home-template .img-row {
      position: relative;
      top: 0; } }

.home-template .custom-template {
  margin: 40px 50px 0px 50px; }

.home-template .project-image {
  top: 0;
  padding: 20px 0px 0px 0px; }

/*speech understanding*/
@media screen and (max-width: 400px) {
  .speech-understanding audio {
    width: 200px; } }

.speech-understanding .heading {
  left: -28.5%;
  z-index: 1; }
  @media screen and (max-width: 767px) {
    .speech-understanding .heading {
      max-width: 300px; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .speech-understanding .heading {
      margin-top: 0;
      margin-bottom: 30px; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .speech-understanding .heading {
      left: 0; } }

@media screen and (max-width: 1366px) {
  .speech-understanding .columns.centered-col {
    float: left; } }

.speech-understanding img.signal {
  position: relative;
  left: -24%;
  max-width: 77%; }
  @media screen and (max-width: 767px) {
    .speech-understanding img.signal {
      position: relative;
      left: auto;
      max-width: 100%; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .speech-understanding img.signal {
      left: 0;
      max-width: 60%; } }

.speech-understanding svg.svg-speech {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 60%;
  height: 270px;
  z-index: 0; }
  @media screen and (max-width: 767px) {
    .speech-understanding svg.svg-speech {
      position: absolute;
      top: 0;
      right: 0;
      height: 60%;
      height: auto;
      max-width: 40%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .speech-understanding svg.svg-speech {
      position: absolute;
      top: 10px;
      right: 0;
      height: 60%;
      height: 42%;
      padding: 60px 10px 0px 0px; } }

.speech-understanding .custom-template {
  margin: 50px 50px 0px 50px; }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .speech-understanding .custom-template {
      margin: 70px 50px 0px 50px; } }

.speech-understanding h3.subtitle {
  color: #2ea7e7;
  margin-bottom: 20px; }

/*glas-ai-brain*/
.glas-ai-brain .heading {
  left: -30%;
  width: 130%; }
  @media screen and (max-width: 991px) {
    .glas-ai-brain .heading {
      margin-bottom: 20px; } }
  @media screen and (min-width: 992px) and (max-width: 1023px) {
    .glas-ai-brain .heading {
      left: 0; } }

@media screen and (max-width: 1366px) {
  .glas-ai-brain .columns.centered-col {
    float: left; } }

.glas-ai-brain .svg-wrapper {
  width: 63%;
  display: block;
  float: right;
  position: absolute;
  top: 120px;
  right: 15px; }
  @media screen and (max-width: 767px) {
    .glas-ai-brain .svg-wrapper {
      top: auto;
      left: 50%;
      width: 80%;
      transform: translateX(-50%); } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .glas-ai-brain .svg-wrapper {
      top: auto;
      left: 50%;
      transform: translateX(-50%);
      width: 65%; } }

.glas-ai-brain .custom-template {
  margin: 70px 20px 0px 40px; }
  @media screen and (max-width: 767px) {
    .glas-ai-brain .custom-template {
      margin: 100px 0px 20px 0px; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .glas-ai-brain .custom-template {
      margin: 100px 0px 20px 0px; } }

/*predictive-analysis*/
.predictive-analysis .heading {
  display: flex;
  left: -69%;
  width: 500px;
  margin-bottom: 50px; }
  @media screen and (min-width: 991px) and (max-width: 1023px) {
    .predictive-analysis .heading {
      left: 0; } }
  @media screen and (min-width: 1023px) and (max-width: 1080px) {
    .predictive-analysis .heading {
      left: -83%; } }
  @media screen and (min-width: 1081px) and (max-width: 1120px) {
    .predictive-analysis .heading {
      left: -78%; } }
  @media screen and (min-width: 1120px) and (max-width: 1200px) {
    .predictive-analysis .heading {
      left: -72%; } }

@media screen and (max-width: 1366px) {
  .predictive-analysis .columns.centered-col {
    float: left; } }

.predictive-analysis .title {
  display: flex;
  flex-direction: column; }

.predictive-analysis h3.subtitle {
  margin-left: 50px;
  width: 130%;
  line-height: 1; }
  @media (max-width: 797px) {
    .predictive-analysis h3.subtitle {
      width: 100%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .predictive-analysis h3.subtitle {
      padding: 0;
      width: 100%;
      margin-bottom: 20px;
      font-size: 1.9rem; } }

.predictive-analysis .project-image {
  top: 0;
  padding: 20px 0px 0px 0px; }

.predictive-analysis .svg-wrapper {
  margin-top: 38px; }
  @media screen and (min-width: 1023px) and (max-width: 1200px) {
    .predictive-analysis .svg-wrapper {
      padding-left: 50px; } }

.predictive-analysis .custom-template {
  margin: 10px 50px 0px 50px; }
  @media (max-width: 767px) {
    .predictive-analysis .custom-template .wrapper {
      margin-top: -40px; } }
  @media (min-width: 1200px) {
    .predictive-analysis .custom-template .wrapper {
      margin-top: -40px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .predictive-analysis .custom-template {
      margin-top: 20px; } }
  @media (min-width: 1200px) {
    .predictive-analysis .custom-template {
      margin: 0px 50px 0px 50px; } }

.news-wrapper {
  margin-bottom: 60px;
  float: left; }

.news-item {
  background: #fff;
  float: left;
  position: relative; }

.article-content {
  width: 60%;
  float: left;
  padding: 30px 40px 40px 40px; }
  @media screen and (max-width: 39.9375em) {
    .article-content {
      width: 100%;
      padding: 15px 20px 30px 20px; } }
  .article-content h2 {
    font-family: "DINPro-Light", sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 20px;
    color: #484949;
    padding-left: 60px;
    position: relative; }
    .article-content h2:before {
      display: inline-block;
      content: "";
      width: 40px;
      height: 3px;
      background: #188ddd;
      top: 10px;
      position: absolute;
      left: 0; }
    @media screen and (max-width: 39.9375em) {
      .article-content h2 {
        padding-left: 0;
        padding-bottom: 10px;
        margin-top: 10px; }
        .article-content h2:before {
          left: 0;
          bottom: 0;
          top: auto; } }
  .article-content p {
    line-height: 30px; }
  .article-content .date {
    color: #188ddd;
    display: inline-block;
    width: 100%;
    text-align: right;
    font-size: 10px;
    font-family: "DINPro-Light", sans-serif;
    font-weight: 600; }
    @media screen and (max-width: 39.9375em) {
      .article-content .date {
        text-align: left; } }

.article-picture {
  width: 40%;
  overflow: hidden;
  position: absolute;
  height: 100%;
  right: 0;
  background-repeat: no-repeat;
  background-position: left center; }
  @media screen and (max-width: 39.9375em) {
    .article-picture {
      width: 100%;
      height: 100px;
      position: relative; } }
  .article-picture img {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (min-width: 640px) and (max-width: 1023px) {
      .article-picture img {
        object-fit: cover; } }

.article-picture.news-1 {
  background-image: url("/assets/img/news/news-1.png"); }

.article-picture.news-2 {
  background-image: url("/assets/img/news/news-2.png"); }

.news-item:nth-child(2n) {
  background: #F5F5F3; }
  .news-item:nth-child(2n) .article-content {
    float: right; }
  .news-item:nth-child(2n) .article-picture {
    left: 0;
    right: auto; }
  .news-item:nth-child(2n) .button {
    background: #cacaca; }
    .news-item:nth-child(2n) .button:hover {
      background: white; }

/* NEWS PAGE */
.news-page .news-wrapper {
  background: #fff; }

.news-page .heading {
  left: 40px;
  margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .news-page .heading {
      padding: 0px 20px; } }
  @media (min-width: 1200px) {
    .news-page .heading {
      left: -28%; } }
  @media (min-width: 1024px) and (max-width: 1200px) {
    .news-page .heading {
      left: 40px; } }

.news-page .news-item {
  border-top: 1px solid #F5F5F3; }

@media screen and (max-width: 767px) {
  .news-page h1.title,
  .news-page h2.light {
    font-size: 30px; } }

.single-news .heading h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: normal; }

.single-news .text-block2 {
  font-family: "DINPro-Light", sans-serif; }

.single-news span {
  margin-top: 20px; }

.team-member {
  overflow: hidden;
  background: #fff; }
  .team-member img {
    transition: opacity 500ms;
    -webkit-backface-visibility: hidden;
    max-height: 199px;
    object-fit: cover;
    width: 100%; }
    @media screen and (max-width: 639px) {
      .team-member img {
        max-height: 100%;
        height: 250px; } }
    .team-member img:hover {
      filter: brightness(105%); }
  .team-member:hover img {
    opacity: 1;
    transition: opacity 250ms; }
  .team-member:hover .member-caption:after {
    transform: translate(0, -5px); }
  @media screen and (min-width: 640px) and (max-width: 1023px) {
    .team-member {
      min-height: 240px;
      max-height: 276px; } }
  @media screen and (min-width: 1024px) {
    .team-member {
      max-height: 290px; } }

.team-block {
  position: relative;
  z-index: 100;
  margin-bottom: 40px;
  opacity: 0; }

.member-caption {
  background: #fff;
  border: 1px solid #eee;
  padding: 15px 15px;
  position: relative;
  line-height: 0; }
  .member-caption:after {
    content: "";
    display: inline-block;
    width: 25px;
    height: 2px;
    background: #188ddd;
    margin-top: 10px;
    transition: all 500ms; }
  .member-caption strong {
    text-transform: uppercase;
    color: #000;
    font-size: 14px;
    line-height: 14px;
    font-family: "DINPro-Bold", sans-serif;
    display: inline-block;
    width: 100%; }
  .member-caption span {
    display: inline-block;
    width: 100%;
    font-size: 13px;
    line-height: 19px;
    position: relative;
    font-family: "DINPro-Medium", sans-serif; }
  @media screen and (min-width: 640px) and (max-width: 991px) {
    .member-caption {
      height: -webkit-fill-available; } }

.test {
  color: red; }

.no-accordeon {
  opacity: 1 !important;
  height: auto !important; }

/* TEAM PAGE */
.team-page h1 {
  text-transform: uppercase; }
  .team-page h1.title {
    padding-left: 15px; }

.team-page .heading {
  line-height: normal;
  width: 320px;
  left: -103%; }
  @media screen and (min-width: 640px) and (max-width: 767px) {
    .team-page .heading {
      left: -85%; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .team-page .heading {
      left: 0; } }
  @media screen and (min-width: 1024px) and (max-width: 1056px) {
    .team-page .heading {
      left: -125%; } }
  @media screen and (min-width: 1057px) and (max-width: 1100px) {
    .team-page .heading {
      left: -120%; } }
  @media screen and (min-width: 1101px) and (max-width: 1118px) {
    .team-page .heading {
      left: -108%; } }
  @media screen and (min-width: 1119px) and (max-width: 1200px) {
    .team-page .heading {
      left: -106%; } }

@media screen and (max-width: 767px) {
  .team-page .text-block {
    padding: 0; } }

.contact-form {
  height: auto;
  padding-bottom: 25px;
  float: right;
  width: 50%; }
  @media screen and (max-width: 767px) {
    .contact-form {
      width: 100%; } }
  .contact-form input,
  .contact-form input:focus,
  .contact-form textarea {
    background: #EBEFF1;
    border: 0;
    color: #000;
    margin-bottom: 5px;
    font-size: 14px;
    font-family: "DINPro-Light", sans-serif; }
  .contact-form textarea {
    height: 120px; }
  .contact-form input.wpcf7-not-valid {
    background: transparent;
    border: 1px solid #ff0000; }
  .contact-form .cta-button {
    background: #188ddd;
    padding: 7px 35px;
    border: none; }
    .contact-form .cta-button:hover {
      background: #1370af;
      color: #fff; }
  .contact-form .text-block {
    margin-bottom: 20px;
    padding: 0 20px; }

.map {
  width: 100%;
  opacity: 0;
  background: #666;
  height: 350px;
  position: relative;
  z-index: 102; }

.address-block {
  position: relative; }
  @media screen and (max-width: 39.9375em) {
    .address-block {
      height: auto !important; } }

.contact-page .visible-mobile {
  display: none; }

.address-wrapper {
  position: absolute;
  opacity: 0;
  bottom: 0;
  width: 100%;
  background: none;
  padding: 0px 30px 30px 60px; }
  @media screen and (max-width: 39.9375em) {
    .address-wrapper {
      position: relative;
      padding: 20px;
      background: #000; } }
  .address-wrapper h2 {
    text-transform: uppercase;
    font-size: 16px;
    color: #000;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 30px; }
    @media screen and (max-width: 767px) {
      .address-wrapper h2 {
        margin-top: 0px; } }
    .address-wrapper h2:after {
      content: '';
      background: #188ddd;
      height: 2px;
      width: 30px;
      display: inline-block;
      bottom: 0;
      left: 0;
      position: absolute; }
  .address-wrapper strong {
    display: inline-block;
    width: 100%;
    font-size: 14px; }
  .address-wrapper span {
    display: inline-block;
    width: 100%;
    font-size: 14px; }
  .address-wrapper p {
    margin-bottom: 10px; }

/* CONTACT PAGE */
.contact-page {
  position: relative;
  padding: 0 30px 6px 60px; }
  .contact-page .address-block {
    height: 100%;
    width: 50%;
    height: 415px !important;
    background: none !important; }
    @media screen and (max-width: 767px) {
      .contact-page .address-block {
        position: relative;
        height: auto !important;
        width: 100%; } }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .contact-page .address-block {
        height: 425px !important; } }
    .contact-page .address-block .address-wrapper {
      position: absolute;
      font-weight: bold;
      padding: 0 30px 6px 60px;
      background: none; }
      @media screen and (max-width: 767px) {
        .contact-page .address-block .address-wrapper {
          padding: 0px 30px 6px 10px;
          position: relative; } }
      .contact-page .address-block .address-wrapper h2 {
        font-weight: bold; }
      .contact-page .address-block .address-wrapper p {
        font-size: 14px; }
  .contact-page .box {
    padding: 0;
    padding-right: 30px; }
    @media screen and (max-width: 767px) {
      .contact-page .box {
        padding: 0; } }
  .contact-page .heading {
    width: 160%;
    display: inline-flex;
    margin-top: 80px;
    left: -44%; }
    @media screen and (max-width: 767px) {
      .contact-page .heading {
        left: 10px !important;
        margin-top: 20px;
        margin-bottom: 20px; } }
    @media screen and (min-width: 768px) and (max-width: 830px) {
      .contact-page .heading {
        left: -60% !important;
        width: 400px !important;
        font-size: 30px; } }
    @media screen and (min-width: 831px) and (max-width: 880px) {
      .contact-page .heading {
        left: -55% !important;
        width: 400px !important; } }
    @media screen and (min-width: 881px) and (max-width: 1023px) {
      .contact-page .heading {
        left: -50% !important;
        width: 400px !important; } }
    @media screen and (min-width: 1023px) and (max-width: 1120px) {
      .contact-page .heading {
        left: -50% !important; } }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      .contact-page .heading h1.title {
        padding-left: 25px;
        font-size: 30px;
        line-height: normal; } }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      .contact-page .heading h2.light {
        font-size: 30px;
        line-height: normal; } }
  .contact-page .text-block2 {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0 20px; }
    @media screen and (max-width: 767px) {
      .contact-page .text-block2 {
        margin-top: 0; } }
  .contact-page .visible-mobile {
    display: none; }
    @media screen and (max-width: 767px) {
      .contact-page .visible-mobile {
        display: block; } }
  .contact-page .alert-message {
    border: 1px solid #f7e700;
    margin: 20px 0px;
    padding: 10px;
    text-align: center; }

.box.gradient.shadow.mb0:before, .box.gradient.shadow.mb0:after {
  display: table;
  clear: both;
  content: ""; }

section.page.contact-page {
  padding: 40px 0 0px; }
  @media screen and (max-width: 767px) {
    section.page.contact-page {
      padding: 0px; } }

.contact-form .cta-button,
.team-page .cta-button {
  background: #1BA1E4;
  padding: 7px 35px;
  color: #fff;
  border: none;
  line-height: 24px; }
  @media screen and (max-width: 767px) {
    .contact-form .cta-button,
    .team-page .cta-button {
      width: 100%;
      text-align: center; } }

.contact-form .cta-button:hover,
.team-page .cta-button:hover {
  background: #1BA1E4;
  color: #fff; }

.contact-form .text-block {
  margin-bottom: 20px;
  padding: 0 20px; }

/*mobile queries*/
@media screen and (max-width: 767px) {
  /*    general*/
  .predictive-analysis h1.title,
  .glas-ai-brain h1.title,
  .home-template h1.title,
  .speech-understanding h1.title, .contact-page h1.title, .team-page h1.title {
    font-size: 30px;
    padding-left: 0; }
  .predictive-analysis .heading,
  .glas-ai-brain .heading,
  .home-template .heading,
  .speech-understanding .heading,
  .contact-page .heading {
    display: block;
    margin-bottom: 20px; }
  .speech-understanding .custom-template,
  .predictive-analysis .custom-template {
    margin-top: 20px; }
  .speech-understanding .custom-template {
    margin: 70px 0px 0px 0px; }
  .predictive-analysis .custom-template,
  .home-template .custom-template, .speech-understanding .custom-template {
    margin: 20px 0px 20px 0px; }
  .predictive-analysis h3.subtitle,
  .home-template h3.subtitle {
    padding: 0;
    margin-left: 0; }
  .hidden-large {
    display: block; }
  .hidden-xs {
    display: none !important; } }

@media (max-width: 1023px) {
  .visible-large {
    display: none; }
  .header .visible-xs {
    display: block; }
  .centered-col {
    float: none !important;
    clear: both;
    margin-right: auto;
    margin-left: auto; } }
