/*mobile queries*/

@media screen and (max-width: 767px) {

  /*    general*/
  .predictive-analysis h1.title,
  .glas-ai-brain h1.title,
  .home-template h1.title,
  .speech-understanding h1.title, .contact-page h1.title, .team-page h1.title {
    font-size: 30px;
    padding-left: 0;
  }

  .predictive-analysis .heading,
  .glas-ai-brain .heading,
  .home-template .heading,
  .speech-understanding .heading,
  .contact-page .heading {
    display: block;
    margin-bottom: 20px;
  }

  .speech-understanding .custom-template,
  .predictive-analysis .custom-template {
    margin-top: 20px;
  }

  .speech-understanding .custom-template {
    margin: 70px 0px 0px 0px;
  }

  .predictive-analysis .custom-template,
  .home-template .custom-template, .speech-understanding .custom-template {
    margin: 20px 0px 20px 0px;
  }

  .predictive-analysis h3.subtitle,
  .home-template h3.subtitle {
    padding: 0;
    margin-left: 0;
  }

  .hidden-large {
    display: block;
  }

  .hidden-xs {
    display: none !important;
  }
}


@media (max-width: 1023px) {
  //hide desktop navbar for width<1023px
  .visible-large {
    display: none;
  }

  .header .visible-xs {
    display: block;
  }
  .centered-col{
    float: none!important;
    clear:both;
    margin-right: auto;
    margin-left: auto;
  }

}