.header {
  padding: 0 0 40px 0;
  background: #f2f2f0;

  .logo {
    width: 155px;
    display: inline-block;
    padding-left: 20px;

    svg {
      width: 100%;
    }

    img {
      @include breakpoint(small only) {
        height:auto;
      }
    }
  }

  @include breakpoint(small only) {
    padding: 0 0 10px 0;
  }
}



.menu-button {
  width: 22px;
  display: inline-block;

  svg {
    width: 100%;
  }

  position: relative;
  top: 20px;

  @include breakpoint(small only) {
    width: 33px;
    top: 15px;
    right: 20px;
    width: 28px;
  }
}

/*NAVBAR DESKTOP*/

.header {
  .visible-xs {
    display: none;
    background: #000;
    height: 63px;
  }

  .visible-large {
    background: #282727;
  }

  ul.desktop-nav {
    margin: 0;
  }

  @media screen and (max-width:767px){
    padding: 0px;
  }
}

.desktop-nav li {

  &.current-menu-item a,
  a.active {
    color: #1d76b5;
  }
}

.header .desktop-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: 100%;
  padding-right: 40px;

  .nav-item {
    font-family: $font-din-light;
    justify-content: space-between;
    color: #fff;
    font-weight: 500;
    font-size: 21px;
    padding: 0px 15px;
    justify-content: flex-end;
    text-transform: uppercase;

    @media (min-width: 1200px) {
      font-size: 16px;
      padding: 0 10px;
    }

    @media (min-width: 1024px) and (max-width: 1200px) {
      font-size: 14px;
      padding: 0 5px;
    }
  }

  a {
    color: #fff;
    transition: border-bottom 0.6s;
    border-bottom: 1px solid transparent;
    
  
    &:hover {
      border-bottom: 1px solid $color-cyan;
    }
  }
}

.visible-large {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.desktop-logo-container {
  width: auto;
  height: auto;
  padding-left: 40px;
  padding-bottom: 15px;

  .logo-desktop {
    img {
      width: 225px;
      height: 75px;

      @media (min-width: 1024px) and (max-width: 1200px) {
        width: auto;
        max-width: 180px;
        height: auto
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1200px) {
    padding-left: 20px;
    padding-bottom: 10px;
  }
}

.nav-items {
  flex-grow: 1;
  justify-content: flex-end;
}

.navigation {
  .menu {
    .nav-item {
      a {
        color: #fff;

        &:hover {
          color: lighten(#fff, 0.3);
        }
      }
    }
  }

  .close-btn svg path {
    fill: #fff;
  }
}