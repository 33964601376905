/*common to templates*/

.predictive-analysis h3.subtitle, .glas-ai-brain h3.subtitle, .home-template h3.subtitle {
  color: #2ea7e7;
  padding: 0px 30px 0px 0px;
}


.predictive-analysis .heading, .glas-ai-brain .heading, .home-template .heading, .speech-understanding .heading, .contact-page .heading, .team-page .heading, .news-page .heading {
  margin-top: 30px;
  width: 100%;
  position: relative;
}

.predictive-analysis h1.title, .glas-ai-brain h1.title, .home-template h1.title, .speech-understanding h1.title, .contact-page h1.title, .team-page h1.title, .news-page h1.title {
  font-size: 35px;
  line-height: normal;
  font-weight: bold;
  padding-left: 30px;
  width: auto;
  display: inline-block;
}

.predictive-analysis p, .home-template p, .glas-ai-brain p, .speech-understanding p, .team-page p {
  text-indent: 15px;
  font-size: 15px;
}

.predictive-analysis h2.light, .glas-ai-brain h2.light, .home-template h2.light, .speech-understanding h2.light, .contact-page h2.light, .team-page h2.light, .news-page h2.light {
  font-size: 35px;
  line-height: normal;
  vertical-align: top;
  display: inline;

  @media screen and (max-width:767px){
    font-size: 30px;
    padding-right: 10px;
  }

}
.predictive-analysis .box, .glas-ai-brain .box, .speech-understanding .box, .home-template .box {
  padding-bottom: 70px;
  @media screen and (max-width:767px){
    padding-bottom: 30px;
  }
}

.hidden-large {
  display: none;
}


//audio styling
.audio-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    span{
      display: flex;
      flex-grow: 1;
      font-family: $font-din-light;
    }
    audio{
      margin-bottom: 6px;
    }
    &.wmb{
      margin-bottom: 12px;
    }
}
audio{
     background: #373737;
     width:270px;
     height:40px;
     @media screen and (min-width:640px) and (max-width:767px){
       width: 200px;
     }
       @media screen and (min-width:768px) and (max-width:991px){
       width: 250px;
     }
       @media screen and (min-width:1023px) and (max-width:1200px){
       width: 250px;
     }

}
audio::-webkit-media-controls-enclosure{
  background: transparent;
  border-radius: 0;
}

audio::-webkit-media-controls-mute-button,
audio::-webkit-media-controls-current-time-display{
  color: #fff;
}
audio::-webkit-media-controls-play-button,
audio::-webkit-internal-media-controls-segmented-track,
audio::-webkit-media-controls-timeline,
audio::-webkit-media-controls-mute-button{
      filter: invert(1000);
}

audio::-webkit-media-controls-time-remaining-display{
// color: #808072;
color: #fff;
}

audio::-webkit-media-controls-enclosure{
  min-height:40px;
  max-height:40px;
}
