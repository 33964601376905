/*predictive-analysis*/

.predictive-analysis {
    .heading {
        display: flex;
        left: -69%;
        width: 500px;
        margin-bottom: 50px;
        @media screen and (min-width: 991px) and (max-width: 1023px) {
            left: 0;
            // width: 80%;
        }
        @media screen and (min-width: 1023px) and (max-width:1080px) {
            left: -83%;
        }
        @media screen and (min-width: 1081px) and (max-width:1120px) {
            left: -78%;
        }
        @media screen and (min-width: 1120px) and (max-width:1200px) {
            left: -72%;
        }
    }
    .columns.centered-col {
        @media screen and (max-width: 1366px) {
            float: left;
        }
    }
    .title {
        display: flex;
        flex-direction: column;
    }
    h3.subtitle {
        margin-left: 50px;
        width: 130%;
        line-height: 1;
        @media (max-width: 797px) {
            width: 100%;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            padding: 0;
            width: 100%;
    	    margin-bottom: 20px;
            font-size: 1.9rem;
        }
    }
    .project-image {
        top: 0;
        padding: 20px 0px 0px 0px;
    }
    .svg-wrapper {
        margin-top: 38px;
        @media screen and (min-width: 1023px) and (max-width: 1200px) {
            padding-left: 50px;
        }
    }
    .custom-template {
        margin: 10px 50px 0px 50px;
        .wrapper {
            @media (max-width: 767px) {
                margin-top: -40px;
            }
            @media (min-width: 1200px) {
                margin-top: -40px;
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {
            margin-top: 20px;
        }
        @media (min-width: 1200px) {
            margin: 0px 50px 0px 50px;
        }
    }
}